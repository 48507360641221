module.exports = [{
      plugin: require('../../../packages/gatsby-theme-soluble-source/gatsby-browser.js'),
      options: {"plugins":[],"createLocalizedNotFound":false,"primaryLanguage":"en","language":{"en":"","es":"es","de":"de","fr":"fr","pt":"pt"},"pathsByLanguage":{"BlogPost":{"en":"blog","es":"blog","de":"blog","fr":"blog","pt":"blog"},"Team":{"en":"blog/author","es":"blog/autor","de":"blog/autor","fr":"blog/auteur","pt":"blog/autor"},"Topic":{"en":"blog/category","es":"blog/categoria","de":"blog/kategorie","fr":"blog/categorie","pt":"blog/categoria"},"BlogPostPaginationPage":{"en":"blog/page","es":"blog/pagina","de":"blog/seite","fr":"blog/page","pt":"blog/pagina"},"Webinar":{"en":"resources/webinars","es":"recursos/webinars","de":"ressourcen/webinars","fr":"ressources/webinars","pt":"recursos/webinars"},"GlossaryTerm":{"en":"glossary","es":"glosario","de":"glossar","fr":"glossaire"},"SuccessStory":{"en":"resources/success-stories","es":"recursos/success-stories","de":"ressourcen/success-stories","fr":"ressources/success-stories","pt":"recursos/success-stories"}},"datocms":{"previewMode":true,"modelPages":["Page","BlogPost","Team","Topic","GlossaryTerm","Webinar","SuccessStory"],"cdnHost":"https://assets.dev.ratedpower.com","mediaPath":"41093","modelsPagination":{"BlogPost":{"pagination":{"firstPageItems":13,"perPage":12},"templateKey":"blog-page","sectionKey":"BlogSection","itemsKey":"posts","orderBy":"updatedDate","sort":"DESC"}}},"slices":{"header":"/home/runner/work/mkt-main-website/mkt-main-website/apps/website/src/components/Header/index.tsx","footer":"/home/runner/work/mkt-main-website/mkt-main-website/apps/website/src/components/Footer/index.tsx"},"local":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RatedPower","short_name":"RatedPower","start_url":"/","background_color":"#ffffff","theme_color":"#63c82d","display":"minimal-ui","icon":"src/img/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d995fe096ee4e81e86f923a42bd2dc20"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MDH229V","includeInDevelopment":false,"gtmAuth":"5GUG8TlR4GCyTMZDNUB5xA","gtmPreview":"env-105","defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
